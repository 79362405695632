/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import Button from '@material-ui/core/Button';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '600'
    }
};
export default class USPItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false

        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal(data) {
        console.log("data" + data)
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-md-12 col-sm-12 cursorPointer">
                    <div className='row'>
                        <div className='col-md-3 col-sm-12'>
                            <p><img alt="" src={process.env.react_app_base_url + "/" + this.props.image} /></p>
                        </div>
                        <div className="col-md-9 col-sm-12" onClick={() => this.handleOpenModal('James')} >
                            <h4>{this.props.title}</h4>
                            <p>{this.props.desc}</p>
                        </div>
                    </div>
                </div>

                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div className="popup-wrapper">
                        <Button className="closeBtn" color="primary" variant="outlined" onClick={this.handleCloseModal}>
                            <span className="ti-close iconSpan"></span> Close
                        </Button>
                        <h4 className="modal-pop-title"><img alt="" src={process.env.react_app_base_url + "/" + this.props.image} /> {this.props.title}</h4>
                        <div className="popup2">
                            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: this.props.fullcontent }}></div>
                        </div>
                    </div>
                </ReactModal>
            </React.Fragment>
        );
    }
}
