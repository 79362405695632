/* eslint-disable require-jsdoc */
import React, { Component, useEffect, useState } from 'react';
import Social1 from '../../images/social1.png'
import Social2 from '../../images/social2.png'
import Social3 from '../../images/social3.png'
import Social4 from '../../images/social4.png'

import { Button } from "@material-ui/core";
import axios from 'axios';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import WLogo from '../../images/whatsapp.png'
import youtubeIcon from '../../images/youtube.png'
import './footer.css';
import TopCourseMenuItem from './TopCourseMenuItem';
import MobileNumbers from './MobileNumbers';
import ContactUsNumber from '../ContactUsNumber'
import SocialTelegram from '../../images/telegram.png'
import PhoneNumber from '../PhoneNumber';

// export default class footer extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             cfa_menu: null,
//             other_menu: null,
//             isVisible: false
//         };
//     }
//     async componentDidMount() {
//         this.getAllCMS();
//     }
//     render() {
//         return (
//         );
//     }
// }


const Footer = () => {
    const [state, setState] = useState({
        cfa_menu: [], other_menu: [], loading: false
    })
    const [isVisible, setIsVisible] = useState(true);
    const location = useLocation()

    const params = useParams()
    console.log("params", params?.title)
    const currentYear = new Date().getFullYear();


    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = 800;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll < heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    return (
        <>

            <a className='whatsAppIcon' target='_blank' rel="noopener noreferrer" href={`https://api.whatsapp.com/send/?phone=919422761663&text=I am interested in ${params?.title ? params?.title : 'your'} course.`}>

                <img src={WLogo} alt="" title='' /></a>
            {isVisible && <NavLink to="#top" className={'backToTop'} >Top</NavLink>}
            <>
                {/* <div className='footerStats'>
                    <div className='stats'>
                        <div className='container'>
                            <div className='row justify-content-center align-content-center'>
                                <div className='col-md-4'>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='footer'>
                    <div className='wrapper'>
                        <div className='row'>

                            <div className='col-md-3'>
                                <div className='socialMenu'>
                                    <h4>Have a question?</h4>
                                    <p>Connect with us on Social Media:</p>
                                    <ul className="social_icons">
                                        <li><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/velocitycorporatetariningcentre/?mibextid=ZbWKwL" fontIcon="ti-facebook" title="">
                                            <img src={Social3} title='' alt='' />
                                        </a> </li>
                                        <li><a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/@velocityclassespune" fontIcon="ti-instagram" title="">
                                            <img src={youtubeIcon} title='' alt='' />
                                        </a> </li>
                                        <li><a rel="noopener noreferrer" target="_blank" href="https://instagram.com/velocity_classes_pune?igshid=YTQwZjQ0NmI0OA==" fontIcon="ti-instagram" title="">
                                            <img src={Social1} title='' alt='' />
                                        </a> </li>
                                        <li><a rel="noopener noreferrer" target="_blank" href="https://t.me/Velocityclassofficial" fontIcon="ti-instagram" title="">
                                            <img src={SocialTelegram} title='' alt='' />
                                        </a>

                                        </li>
                                    </ul>


                                    <p><span className="ti-email iconSpan"></span>  Email : <a href="mailto:info@vctcpune.com" title="info@vctcpune.com">info@vctcpune.com</a> </p>
                                    <p className='copyRight'>Copyright {currentYear} VCTC Pune . All Rights Reserved </p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <h4>Velocity</h4>
                                <ul className=''>
                                    <li>
                                        <NavLink to="/" > Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about-us" > About Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/certificate-courses" > Courses</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/upcoming-batches">Upcoming Batches</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/testimonials" > Testimonials</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact-us" > Contact Us</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/cms/privacy-policy/63b3f58faf1f143e1adead8e" > Privacy Policy</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/cms/return-refund-policy/63b3f5c8af1f143e1adead92" > Return & Refund Policy</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/cms/terms-conditions/63b3f54daf1f143e1adead82" > Terms and Conditions</NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className='col-md-4'>
                                <h4>Courses</h4>
                                <TopCourseMenuItem location="footer" handleOpen2={() => this.handleOpen2()} ></TopCourseMenuItem>
                            </div>

                            <div className='col-md-2'>
                            <h4>Course Enquiry</h4>
                            <PhoneNumber></PhoneNumber>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className='footer footer2'>
                    <div className='wrapper'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h4>Contact us</h4>
                                <div className='inner'>
                                    <ContactUsNumber></ContactUsNumber>
                                    <div className='clearfix'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        </>
    )

}
export default Footer
