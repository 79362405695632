/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import AboutImage from '../../images/aboutUs.jpg';
import axios from 'axios';
import FreeResources from '../../components/website/freeResources';
import Placeholder from '../../images/placeholder.jpg'
import { Button } from '@material-ui/core';
import { Loader } from '../../components/website/Loader';
import MissionIcon from '../../images/mission.png'
import VissionIcon from '../../images/vision.png'
import dailyTasks from '../../images/daily-tasks.png'
import iconMeeting from '../../images/meeting.png'
import iconHomeOffice from '../../images/home-office.png'
import GuidanceIcon from '../../images/guidance.png'
import meetingIcon from '../../images/meeting-1.png'
import podiumIcon from '../../images/podium.png'
import interviewIcon from '../../images/interview.png'
import WhyUs from '../../components/WhyUs';

// export const WhyUs = [
//     {
//         "text": "Daily Mock interview",
//         "icon": dailyTasks
//     },
//     { "text": "Weekly Mock interview", "icon": iconMeeting },
//     { "text": "Free Study Room", "icon": iconHomeOffice },
//     { "text": "Career Guidance", "icon": GuidanceIcon },
//     { "text": "Senior Students Guidance", "icon": meetingIcon },
//     { "text": "Students Felicitations", "icon": podiumIcon },
//     { "text": "weekly Assignment", "icon": interviewIcon }
// ]

export default class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null,
            tableData: null
        };

    }

    async componentDidMount() {
        this.getAllPages();
        this.getAllCMS2('Instructor');
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)
                const meet_trainer = res.data.data.filter(item => item._id == "5f7746278e396339ca9b03d2");
                console.log("meet_trainer", meet_trainer[0])
                this.setState({ pages: meet_trainer[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }





    async getAllCMS2(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Testimonials res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="About VCTC Pune "></SubHeader>
                <div className="section aboutus">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-sm-12 text-left">
                                {this.state.pages === null ? <Loader></Loader> : <div className="ql-editor" dangerouslySetInnerHTML={{ __html: this.state.pages?.description }}></div>}
                            </div>
                        </div>
                    </div>
                </div>


                <div id='AboutSection'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12'>
                                <h1>About Us</h1>
                                <p>Velocity Pune is a premier IT training institute dedicated to empowering individuals with the skills and knowledge required to excel in the dynamic field of information technology. Our comprehensive curriculum, experienced faculty, and state-of-the-art facilities ensure that students receive industry-relevant education, preparing them for successful careers in the IT sector. Offering a diverse range of courses, from foundational programming languages to advanced technologies, we cater to both beginners and professionals seeking to enhance their expertise.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='AboutSection'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-sm-12 col-md-6'>
                                <div className='inner'>
                                    <img src={MissionIcon} alt='' title='' />
                                    <h1>Our Mission</h1>
                                    <p>Our mission is to deliver world-class IT education by offering cutting-edge, industry-aligned training programs that combine technical expertise with practical learning. We strive to inspire creativity, nurture innovation, and develop holistic skill sets that enable students to thrive in the competitive IT landscape. Through a commitment to lifelong learning and inclusivity, we aim to bridge the skills gap, advance technological growth, and empower individuals to transform their aspirations into reality.</p>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-6'>
                                <div className='inner'>
                                    <img src={VissionIcon} alt='' title='' />
                                    <h1>Our Vision</h1>
                                    <p>Our vision is to become a leading center of excellence in IT education, recognized globally for delivering transformative, industry-relevant training. We aspire to empower individuals with the skills, knowledge, and confidence to innovate, lead, and contribute meaningfully to the ever-evolving world of technology. By fostering a culture of excellence and inclusivity, we aim to shape future-ready professionals who drive progress and create a positive impact on society.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <WhyUs></WhyUs>
              

            </React.Fragment>
        );
    }
}
