/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import CourseImage from '../../images/blog-details.jpg';
import Blog from '../../components/website/blog';
import axios from 'axios';
import DefaultImage from '../../images/placeholder.jpg';
import moment from 'moment';
import Comments from '../../components/website/Comments';

import CommentIcon from '@material-ui/icons/Comment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { NavLink } from "react-router-dom";

import { withSnackbar } from 'notistack';
import { Link, animateScroll as scroll } from "react-scroll";

import Button from '@material-ui/core/Button';
import Autocomplete from '../../components/website/search';
import LinkItem from "../../components/LinkItem";
import FreeResources from '../../components/website/freeResources';
import { Loader } from '../../components/website/Loader';

class BlogDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null,
            fields: {
                user_id: localStorage.getItem('user_id') || null,
                user_name: localStorage.getItem('user_name') || null,
                user_email: localStorage.getItem('user_email') || null,
                user_comments: '',
            },
            organization_id: '5f048db166e1c93c780e7e7c',
            cms_id: null,
            commentsLength: null,
            visitLength: null,
            autSuggestion: [],
            cms_category: []

        };

        this.getCommentCount = this.getCommentCount.bind(this)


    }

    getCommentCount(dataFromChild) {

        this.setState({ commentsLength: dataFromChild })
        console.log("likeLength", dataFromChild)
    }


    async componentDidMount() {
        console.log("this.props.match.params.id", this.props.match.params.id)
        console.log("this.props.match.params.title", this.props.match.params.title)
        const id = this.props.match.params.id
        const title = this.props.match.params.title
        this.setState({ cms_id: id })
        this.getAllCMS(id);
        this.getAllLikes(id);
        this.visit(id, title);
        this.getAllVisit(id, title);
        this.getAllCMSBlog('Blog');
        this.getCategoriesByCMSType('Blog');
    }


    componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps.match.params.title)
        console.log("nextProps", nextProps.match.params.id)
        const id = nextProps.match.params.id
        const title = nextProps.match.params.title
        this.setState({ cms_id: id })
        this.getAllCMS(id);
        this.getAllLikes(id);
        this.visit(id, title);
        this.getAllVisit(id, title);
        this.getAllCMSBlog('Blog');
        this.getCategoriesByCMSType('Blog');
    }

    async getAllCMSBlog(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Blog res", res)

                const filteredDates = res.data.data.sort((a, b) => new Date(b.cms_publish_date) - new Date(a.cms_publish_date))
                console.log("filteredDates", filteredDates)

                const arrayList = []
                {
                    filteredDates.map((optionName, index) => {
                        console.log("optionName", optionName.cms_title)
                        console.log("index", index)
                        arrayList.push(optionName.cms_title)

                    })
                }

                console.log("arrayList", arrayList)

                this.setState({ autSuggestion: filteredDates, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    async getCategoriesByCMSType(cms_type) {

        // console.log("getCategoriesByCMSType, cms_type", token, cms_type)


        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms_categories_by_cms_type/' + cms_type)
            .then(res => {
                // console.log("getCategoriesByCMSType res", res)

                this.setState({ cms_category: res.data.data })

            })
            .catch(error => {
                console.log("error.response.status", error)
                if (error.response.status === 400) {

                    console.log("API error", error);
                    console.log("error.response", error.response.data.message);

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                } else if (error.response.status === 401) {

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    this.props.history.push({
                        pathname: '/admin/'
                    });

                } else {

                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                }
            });

    }

    async getAllCMS(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/' + id)
            .then(res => {
                console.log("Courses res", res)
                this.setState({ tableData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getAllLikes(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/likes/byPostId/' + id)
            .then(res => {
                console.log("Courses res", res)
                this.setState({ likeLength: res.data.data.length, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getAllVisit(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/visit/byPostId/' + id)
            .then(res => {
                console.log("getAllVisit Res", res)
                this.setState({ visitLength: res.data.data.length, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }


    like = () => {

        const formData = new FormData();
        formData.append('user_id', this.state.fields.user_id);
        formData.append('organization_id', this.state.organization_id);
        formData.append('user_name', this.state.fields.user_name);
        formData.append('user_email', this.state.fields.user_email);
        formData.append('like', "Like");
        formData.append('post_id', this.state.cms_id);
        formData.append('post_name', this.state.cms_title);
        formData.append('status', '1');

        console.log(JSON.stringify(formData));

        axios.post(process.env.react_app_base_url + '/api/v1/likes', formData)
            .then(res => {
                console.log("res", res)
                const message = res.data.messages;

                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 7000,
                });

                this.getAllLikes(this.state.cms_id)

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });

    }


    visit = (id, title) => {

        const formData = new FormData();
        formData.append('organization_id', this.state.organization_id);
        formData.append('post_id', id);
        formData.append('post_name', title);
        formData.append('status', '1');

        console.log(JSON.stringify(formData));

        axios.post(process.env.react_app_base_url + '/api/v1/visit', formData)
            .then(res => {
                console.log("res", res)
                // const message = res.data.messages;
                // this.props.enqueueSnackbar(message, {
                //     variant: 'info',
                //     autoHideDuration: 7000,
                // });

                this.getAllLikes(this.state.cms_id)

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });

    }

    render() {
        return (
            <React.Fragment>
                {this.state.tableData == null ?  <Loader></Loader> :
                    <React.Fragment>
                        {/* <SubHeader
                            para={`Date - ${moment(this.state.tableData.created_on).format('D/M/yyyy')}`}
                            title={`${this.state.tableData.cms_title}`}></SubHeader> */}

                        <div className='bannerBlog'>
                            <div className='inner'>
                                <h2>Blog - {this.state.tableData.cms_title}</h2>
                                <p>Date - {moment(this.state.tableData.created_on).format('D/M/yyyy')} 
                                {this.state.tableData?.cms_category && `| ${this.state.tableData?.cms_category}`} 
                                    {this.state.tableData?.author && `| ${this.state.tableData?.author}`}
                                </p>

                                {/* {JSON.stringify(this.state.tableData?.cms_category)} */}
                            </div>
                        </div>

                        <div className="wrapperContent">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col-sm-12 text-left">
                                        <div className="image" style={{ float: 'left' }}>
                                            {this.state.tableData.cms_image == null ? <img src={DefaultImage} alt="" /> : <img src={process.env.react_app_base_url + "/" + this.state.tableData.cms_image} alt="" />}
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="pt-3">
                                            <div className="description">
                                                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: this.state.tableData.cms_description }}></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-12 text-left">
                                        <div className="blog-sidebar">
                                            <div className="item">
                                                <Autocomplete
                                                    options={this.state.autSuggestion}
                                                />
                                            </div>
                                            <div className="item">
                                                <h5>Category</h5>
                                                <ul className="cat-list">
                                                    {this.state.cms_category.map((item) => {
                                                        return (
                                                            <li value={item.title}>
                                                                <LinkItem to={'/blog/' + item.title} title={item.title}></LinkItem> </li>
                                                        );
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div className="like">
                                <ul>
                                    <li className="thumbnail_icon"><Link activeClass="active" to="comments" spy={true} smooth={true} offset={-150} duration={500} >  {this.state.commentsLength} <CommentIcon></CommentIcon> </Link> </li>
                                    <li className="thumbnail_icon" onClick={this.like}> {this.state.likeLength}  <ThumbUpAltIcon></ThumbUpAltIcon> </li>
                                    <li> {this.state.visitLength} <VisibilityIcon></VisibilityIcon></li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-7 col-sm-12 text-left">
                                    <Comments action={this.getCommentCount} cms_title={this.state.tableData.cms_title}
                                        cms_id={this.state.tableData._id}
                                    ></Comments>

                                </div>
                                <div className="col-md-3 col-sm-12 text-left"></div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <div className='blog_resource'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>Resources</h2>
                            </div>
                            <div className='col-md-6'><FreeResources></FreeResources></div>
                            <div className='col-md-6'><Blog></Blog></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(BlogDetails);