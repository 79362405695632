/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import LinkItem from '../../components/LinkItem';
import axios from 'axios';
import courseImage from '../../images/courseImage.png';
import { Loader } from '../../components/website/Loader';
import { NavLink } from 'react-router-dom';

import powerBI from '../../images/power-bi.png';
import datascience from '../../images/data-science.png';
import javadevelopment from '../../images/java-development.png';
import awsdevops from '../../images/aws-devops.png';
import rpa from '../../images/robotics-process-automation-development-rpa.png';
import elttesting from '../../images/elt-testing.png';
import angulardevelopment from '../../images/angular-development.png';
import bigdata from '../../images/hadoop-big-data-data-engineer.png';
import salesforce from '../../images/sales-force.png';
import softwaretesting from '../../images/software-testing.png';
import saleforceMain from '../../images/saleforceMain.png';

export default class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }

    async componentDidMount() {

        this.getAllCMS();

    }


    // async getAllCMS() {
    //     this.setState({ loading: true })
    //     axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
    //         .then(res => {
    //             console.log("Courses res", res)
    //             this.setState({ tableData: res.data.data, loading: false })
    //         })
    //         .catch(error => {
    //             console.log("error.response.status", error)
    //         });
    // }

    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0&sortBy=order_id=-1')
            .then(res => {
                console.log("Courses res", res)
                const cfa_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)
                const other_menu = res.data.data.sort((a, b) => new Date(a.order_id) - new Date(b.order_id)).filter(item => item.parent_course !== "CFA");
                console.log("other_menu", other_menu)
                this.setState({
                    tableData: res.data.data, loading: false
                    // cfa_menu: cfa_menu, other_menu: other_menu, loading: false
                })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Courses"></SubHeader>
                <div className="container">
                    <div className="coursesComp">

                        <p>Achieving placement is not an easy task, but we get this because of our industry-oriented training. Along with providing Selenium Software Testing, Java Development, Python courses in Katraj, Chinchwad, Chandan Nagar, Thane Mumbai with the latest technologies, we have taken the pledge to brighten the future of engineers.</p>
                        <h4>We Offer Following Courses</h4>
                        <div className='row'>


                            {this.state.tableData === null ?
                                <Loader></Loader>
                                : this.state.tableData.map(item => {
                                    const link = `/courses/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"
                                    return (
                                        <React.Fragment>
                                            <div className='col-md-6  mb-5'>
                                                <div className='inner'>
                                                    <div className="row">
                                                        <div className='col-md-12'>
                                                            <img src={item?.image?.length > 5 ? process.env.react_app_base_url + '/' + item?.image :
                                                                item?.name === "Software Testing" ? softwaretesting :
                                                                    item?.name === "Java Development" ? javadevelopment :
                                                                        item?.name === "Power-BI" ? powerBI :
                                                                            item?.name === "AWS/DevOps" ? awsdevops :
                                                                                item?.name === "Robotics Process Automation Development (RPA)" ? rpa :
                                                                                    item?.name === "Angular Development" ? angulardevelopment :
                                                                                        this.state.tableData?.name === "Salesforce Admin+Development" ? saleforceMain :
                                                                                            item?.name === "Data Science" ? datascience :
                                                                                                item?.image === 'null' ? courseImage : process.env.react_app_base_url + '/' + item?.image} alt='' title='' />

                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='wrapperInner'>
                                                                <h3>{item.name}</h3>
                                                                <p>{item.short_description}</p>
                                                                <p className='courseDuration'>Duration - {item?.duration}</p>
                                                                <div className='row footerCourse'>
                                                                    <div className='col-sm-12 col-md-12 text-left'>
                                                                        <NavLink to={`/courses/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"}>View Courses</NavLink>
                                                                    </div>
                                                                </div>
                                                                <div className='clearfix'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
